import React from "react";
import ServiceProductIcon, {getSvgServiceIconColor, IServiceProductIcon} from "./ServiceIcon";
import isUndefined from "lodash/isUndefined";

const RepostPostIcon: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const color = getSvgServiceIconColor(!isUndefined(isActive) ? isActive : false);

    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 28 28" fill="none">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M9.92362 5C9.92362 4.44772 10.3713 4 10.9236 4H19.8472C21.8495 4 23.3968 5.68456 23.3968 7.66686V21.4298L25.4984 19.2315C25.8801 18.8323 26.5131 18.8181 26.9123 19.1997C27.3115 19.5814 27.3257 20.2144 26.9441 20.6136L22.3968 25.37L17.8496 20.6136C17.468 20.2144 17.4822 19.5814 17.8814 19.1997C18.2806 18.8181 18.9136 18.8323 19.2953 19.2315L21.3968 21.4298V7.66686C21.3968 6.70343 20.6612 6 19.8472 6H10.9236C10.3713 6 9.92362 5.55228 9.92362 5ZM5.82441 5.41576L10.3716 10.1721C10.7533 10.5713 10.7391 11.2043 10.3399 11.586C9.94065 11.9676 9.30765 11.9534 8.926 11.5542L6.82441 9.35594V21.256C6.82441 22.2194 7.56009 22.9229 8.37401 22.9229H17.2976C17.8499 22.9229 18.2976 23.3706 18.2976 23.9229C18.2976 24.4751 17.8499 24.9229 17.2976 24.9229H8.37401C6.37172 24.9229 4.82441 23.2383 4.82441 21.256V9.35594L2.72282 11.5542C2.34117 11.9534 1.70816 11.9676 1.30896 11.586C0.909762 11.2043 0.895534 10.5713 1.27718 10.1721L5.82441 5.41576Z"
                      fill={color}/>
            </svg>
        </ServiceProductIcon>
    )
}

export default RepostPostIcon
