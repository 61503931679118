import React from "react";
import ServiceProductIcon, {getSvgServiceIconColor, IServiceProductIcon} from "./ServiceIcon";
import isUndefined from "lodash/isUndefined";

const BookmarkPostIcon: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const color = getSvgServiceIconColor(!isUndefined(isActive) ? isActive : false);

    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 26 27">
                <g>
                    <g id="Layer_1">
                        <path className="cls-1"
                              d="M21.2,6.4v18.2l-7.8-4.8-.4-.3-.4.3-7.8,4.8V6.4c0-1.7,1.3-3,3-3h10.3c1.7,0,3,1.3,3,3Z" fill={color}/>
                    </g>
                </g>
            </svg>
        </ServiceProductIcon>
    )
}

export default BookmarkPostIcon
