import React from "react";
import {styled} from "@mui/material/styles";
import Container from "@mui/material/Container";
import {useTrans} from "@core/Hook/trans";
import HomepageSectionLayout from "./HomepageSectionLayout";
import Title from "../../Component/Title";
import {Image} from "@core/Utils/image";
import {Card, CardActions, CardContent, CardMedia} from "@mui/material";
import PlainButton from "../../Component/Button/PlainButton";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {
    PRODUCT_CODE_FACEBOOK,
    PRODUCT_CODE_INSTAGRAM,
    PRODUCT_CODE_TIKTOK,
    PRODUCT_CODE_X
} from "../../Constants/product";
import FadeInUp from "../../Animate/FadeInUp";
import {useManageProductInfo} from "../../Hook/product";

const OurServiceStyled = styled(Container)(({theme}) => ({
    '&.x-homepage-our-service-container': {
        '.-our-server-wrapper': {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                marginTop: theme.spacing(3),
            },
            '.-card-wrapper': {
                background: theme.baseVariables.ourService.cardBg,
                [theme.breakpoints.up('sm')]: {
                    width: '48%',
                },
            },
            '.-card-media-wrapper': {
                padding: theme.spacing(1),
                '.-our-service-banner-img': {
                    borderRadius: theme.spacing(1),
                }
            },
            '.-card-action-wrapper': {
                padding: theme.spacing(0,  0, 3),
                justifyContent: 'center'
            }
        }
    }
}))

const HomepageOurService: React.FC = () => {
    const {trans} = useTrans();
    const navigate = useNavigate();
    const { productChooseInfo, saveProductPlatform, clearProductInfo } = useManageProductInfo();
    const contents = [
        {
            title: trans('homepage.section.our_service.facebook.title'),
            description: trans('homepage.section.our_service.facebook.description'),
            banner: 'wow-boost-homepage-our-service-facebook.png',
            extraClass: '-facebook-text',
            btnExtraClass: '-facebook',
            product: PRODUCT_CODE_FACEBOOK,
        },
        {
            title: trans('homepage.section.our_service.tiktok.title'),
            description: trans('homepage.section.our_service.tiktok.description'),
            banner: 'wow-boost-homepage-our-service-tiktok.png',
            extraClass: '-tiktok-text',
            btnExtraClass: '-tiktok',
            product: PRODUCT_CODE_TIKTOK,
        },
        {
            title: trans('homepage.section.our_service.instagram.title'),
            description: trans('homepage.section.our_service.instagram.description'),
            banner: 'wow-boost-homepage-our-service-instagram.png',
            extraClass: '-instagram-text',
            btnExtraClass: '-instagram',
            product: PRODUCT_CODE_INSTAGRAM,
        },
        {
            title: trans('homepage.section.our_service.x.title'),
            description: trans('homepage.section.our_service.x.description'),
            banner: 'wow-boost-homepage-our-service-x.png',
            extraClass: '-x-text',
            btnExtraClass: '-x',
            product: PRODUCT_CODE_X,
        },
    ]

    const onSelectProduct = (productPlatformCode: string) => {
        if (productChooseInfo.platform?.code !== productPlatformCode) clearProductInfo();

        saveProductPlatform({
            label: productPlatformCode,
            code: productPlatformCode
        })

        navigate(`/services`);
    }

    return (
        <HomepageSectionLayout sectionId={'homepageSectionOurService'}>
            <OurServiceStyled className="x-homepage-our-service-container">
                <FadeInUp>
                    <Title title={trans('homepage.section.our_service.title')} subTitle={trans('homepage.section.our_service.sub_title')}/>
                </FadeInUp>
                <FadeInUp>
                    <Box className={'-our-server-wrapper'} gap={2}>
                        {contents.map((item, index) => {
                            return (
                                <Card variant="outlined" key={`${index}-${item.title}`} className={'-card-wrapper'}>
                                    <CardMedia className={'-card-media-wrapper'}>
                                        <Image
                                            extraClass={'-our-service-banner-img'}
                                            src={item.banner}
                                            width={746}
                                            height={424}
                                        />
                                    </CardMedia>
                                    <CardContent>
                                        <Title title={item.title} subTitle={item.description} extraClass={item.extraClass}/>
                                    </CardContent>
                                    <CardActions className={'-card-action-wrapper'}>
                                        <PlainButton
                                            extraClass={item.btnExtraClass}
                                            label={trans('general.see_more')}
                                            onClick={() => onSelectProduct(item.product)}
                                        />
                                    </CardActions>
                                </Card>
                            )
                        })}
                    </Box>
                </FadeInUp>
            </OurServiceStyled>
        </HomepageSectionLayout>
    )
}

export default HomepageOurService
