import BoostFollowerIcon from "../Component/Icon/BoostFollowerIcon";
import BoostLikeIcon from "../Component/Icon/BoostLikeIcon";
import BoostViewIcon from "../Component/Icon/BoostViewIcon";
import BoostShareIcon from "../Component/Icon/BoostShareIcon";
import BoostLiveViewIcon from "../Component/Icon/BoostLiveViewIcon";
import BoostCommentIcon from "../Component/Icon/BoostCommentIcon";
import ReportIcon from "../Component/Icon/ReportIcon";
import ThaiUserIcon from "../Component/Icon/ThaiUserIcon";
import ForeignUserIcon from "../Component/Icon/ForeignUserIcon";
import BoostCommentFileIcon from "../Component/Icon/BoostCommentFileIcon";
import BoostCommentEmojiIcon from "../Component/Icon/BoostCommentEmojiIcon";
import BoostEmojiHappySetIcon from "../Component/Icon/BoostEmojiHappySetIcon";
import BoostEmojiInLoveSetIcon from "../Component/Icon/BoostEmojiInLoveSetIcon";
import BoostEmojiCongratulationSetIcon from "../Component/Icon/BoostEmojiCongratulationSetIcon";
import BoostEmojiAngrySetIcon from "../Component/Icon/BoostEmojiAngrySetIcon";
import FacebookIcon from "../Component/Icon/FacebookIcon";
import TikTokIcon from "../Component/Icon/TikTokIcon";
import InstagramIcon from "../Component/Icon/InstagramIcon";
import XIcon from "../Component/Icon/XIcon";
import UploadContentIcon from "../Component/Icon/UploadContentIcon";
import BoostViewStory from "../Component/Icon/BoostViewStoryIcon";
import RepostPostIcon from "../Component/Icon/RepostPostIcon";
import BookmarkPostIcon from "../Component/Icon/BookmarkPostIcon";

////////////////////////////////
//  Client side product code  //
////////////////////////////////

export const PRODUCT_CODE_FACEBOOK = 'facebook';
export const PRODUCT_CODE_TIKTOK = 'tiktok';
export const PRODUCT_CODE_INSTAGRAM = 'instagram';
export const PRODUCT_CODE_X = 'x';
export const AVAILABLE_PLATFORMS = [PRODUCT_CODE_FACEBOOK, PRODUCT_CODE_TIKTOK, PRODUCT_CODE_INSTAGRAM, PRODUCT_CODE_X];

export const SERVICE_BOOST_FOLLOWER = 'boost_follower';
export const SERVICE_BOOST_LIKE = 'boost_like';
export const SERVICE_BOOST_LIKE_POST = 'boost_like_post';
export const SERVICE_BOOST_FAVORITE = 'boost_favorite';
export const SERVICE_BOOST_VIEW = 'boost_view';
export const SERVICE_BOOST_VIEW_VIDEO = 'boost_view_video';
export const SERVICE_BOOST_SHARE = 'boost_share';
export const SERVICE_BOOST_QUOTE_POST = 'boost_quote_post';
export const SERVICE_BOOST_ADD_NOTE = 'boost_add_note';
export const SERVICE_BOOST_SHARE_STORY = 'boost_share_story';
export const SERVICE_BOOST_REPOST_POST = 'boost_repost_post';
export const SERVICE_BOOST_BOOKMARK_POST = 'boost_bookmark_post';
export const SERVICE_BOOST_VIEW_STORY = 'boost_view_story';
export const SERVICE_BOOST_LIVE_VIEW = 'boost_live_view';
export const SERVICE_BOOST_COMMENT = 'boost_comment';
export const SERVICE_BOOST_COMMENT_POST = 'boost_comment_post';
export const SERVICE_UPLOAD_CONTENT = 'upload_content';
export const SERVICE_REPORT = 'report';
export const SERVICE_REPORT_SPAM_POST = 'report_spam_post';
export const AVAILABLE_SERVICES = [
    SERVICE_BOOST_FOLLOWER,
    SERVICE_BOOST_LIKE,
    SERVICE_BOOST_LIKE_POST,
    SERVICE_BOOST_FAVORITE,
    SERVICE_BOOST_VIEW,
    SERVICE_BOOST_VIEW_VIDEO,
    SERVICE_BOOST_VIEW_STORY,
    SERVICE_BOOST_SHARE,
    SERVICE_BOOST_SHARE_STORY,
    SERVICE_BOOST_QUOTE_POST,
    SERVICE_BOOST_ADD_NOTE,
    SERVICE_BOOST_LIVE_VIEW,
    SERVICE_BOOST_COMMENT,
    SERVICE_BOOST_COMMENT_POST,
    SERVICE_BOOST_REPOST_POST,
    SERVICE_BOOST_BOOKMARK_POST,
    SERVICE_REPORT,
    SERVICE_REPORT_SPAM_POST,
    SERVICE_UPLOAD_CONTENT
];

export const SERVICE_OPTION_BOOST_COMMENT_FILE = 'boost_comment_file';
export const SERVICE_OPTION_BOOST_COMMENT_EMOJI = 'boost_comment_emoji';

export const SERVICE_OPTION_CUSTOM_COMMENT_FILE = 'option_custom_file'
export const SERVICE_OPTION_EMOJI_HAPPY_SET = 'option_emoji_happy_set'
export const SERVICE_OPTION_EMOJI_IN_LOVE_SET = 'option_emoji_in_love_set'
export const SERVICE_OPTION_EMOJI_CONGRATULATION_SET = 'option_emoji_congratulation_set'
export const SERVICE_OPTION_EMOJI_ANGRY_SET = 'option_emoji_angry_set'

export const AVAILABLE_SERVICE_OPTIONS = [SERVICE_OPTION_BOOST_COMMENT_FILE, SERVICE_OPTION_BOOST_COMMENT_EMOJI];

export const TARGET_NONE_USER = 'none';
export const TARGET_THAI_USER = 'thai_user';
export const TARGET_FOREIGN_USER = 'foreign_user';
export const AVAILABLE_TARGET_USERS = [TARGET_THAI_USER, TARGET_FOREIGN_USER, TARGET_NONE_USER];

////////////////////////////////
//  Server side product code  //
////////////////////////////////

const SERVER_PRODUCT_CODE_FACEBOOK = 'facebook';
const SERVER_PRODUCT_CODE_TIKTOK = 'tiktok';
const SERVER_PRODUCT_CODE_INSTAGRAM = 'instagram';
const SERVER_PRODUCT_CODE_X = 'x';

const SERVER_SERVICE_BOOST_FOLLOWER = 'follow';
const SERVER_SERVICE_BOOST_FAVORITE = 'favorite-post';
const SERVER_SERVICE_BOOST_LIKE = 'like';
const SERVER_SERVICE_BOOST_LIKE_POST = 'like-post';
const SERVER_SERVICE_BOOST_VIEW = 'view';
const SERVER_SERVICE_BOOST_VIEW_VIDEO = 'view-video';
const SERVER_SERVICE_BOOST_LIVE_VIEW = 'liveview';
const SERVER_SERVICE_BOOST_ADD_NOTE = 'add-note';
const SERVER_SERVICE_BOOST_COMMENT = 'comment';
const SERVER_SERVICE_BOOST_COMMENT_POST = 'comment-post';
const SERVER_SERVICE_BOOST_QUOTE_POST = 'quote-post';
const SERVER_SERVICE_BOOST_SHARE = 'share';
const SERVER_SERVICE_BOOST_SHARE_STORY = 'share-story';
const SERVER_SERVICE_BOOST_VIEW_STORY = 'view-story';
const SERVER_SERVICE_REPOST_POST = 'repost-post';
const SERVER_SERVICE_BOOKMARK_POST = 'bookmark-post';
const SERVER_SERVICE_UPLOAD_CONTENT = 'upload-content';
const SERVER_SERVICE_REPORT = 'report';
const SERVER_SERVICE_REPORT_SPAM_POST = 'report-spam-post';

const SERVER_SERVICE_OPTION_BOOST_COMMENT_FILE = 'file';
const SERVER_SERVICE_OPTION_BOOST_COMMENT_EMOJI = 'emoji';

const SERVER_SERVICE_OPTION_EMOJI_HAPPY_SET = 'happy'
const SERVER_SERVICE_OPTION_EMOJI_IN_LOVE_SET = 'love'
const SERVER_SERVICE_OPTION_EMOJI_CONGRATULATION_SET = 'congrat'
const SERVER_SERVICE_OPTION_EMOJI_ANGRY_SET = 'angry'

const SERVER_TARGET_THAI_USER = 'thai';
const SERVER_TARGET_FOREIGN_USER = 'foreigner';

export const mapServerProductCodeToClientProductCode = (code: string): string => {
    switch (code) {
        case SERVER_PRODUCT_CODE_FACEBOOK: return PRODUCT_CODE_FACEBOOK;
        case SERVER_PRODUCT_CODE_TIKTOK: return PRODUCT_CODE_TIKTOK;
        case SERVER_PRODUCT_CODE_INSTAGRAM: return PRODUCT_CODE_INSTAGRAM;
        case SERVER_PRODUCT_CODE_X: return PRODUCT_CODE_X;
        case SERVER_SERVICE_BOOST_FOLLOWER: return SERVICE_BOOST_FOLLOWER;
        case SERVER_SERVICE_BOOST_LIKE: return SERVICE_BOOST_LIKE;
        case SERVER_SERVICE_BOOST_FAVORITE: return SERVICE_BOOST_FAVORITE;
        case SERVER_SERVICE_BOOST_LIKE_POST: return SERVICE_BOOST_LIKE_POST;
        case SERVER_SERVICE_BOOST_VIEW_STORY: return SERVICE_BOOST_VIEW_STORY;
        case SERVER_SERVICE_BOOST_VIEW: return SERVICE_BOOST_VIEW;
        case SERVER_SERVICE_BOOST_VIEW_VIDEO: return SERVICE_BOOST_VIEW_VIDEO;
        case SERVER_SERVICE_BOOST_LIVE_VIEW: return SERVICE_BOOST_LIVE_VIEW;
        case SERVER_SERVICE_BOOST_COMMENT: return SERVICE_BOOST_COMMENT;
        case SERVER_SERVICE_BOOST_COMMENT_POST: return SERVICE_BOOST_COMMENT_POST;
        case SERVER_SERVICE_BOOST_ADD_NOTE: return SERVICE_BOOST_ADD_NOTE;
        case SERVER_SERVICE_BOOST_SHARE: return SERVICE_BOOST_SHARE;
        case SERVER_SERVICE_BOOST_QUOTE_POST: return SERVICE_BOOST_QUOTE_POST;
        case SERVER_SERVICE_BOOST_SHARE_STORY: return SERVICE_BOOST_SHARE_STORY;
        case SERVER_SERVICE_REPOST_POST: return SERVICE_BOOST_REPOST_POST;
        case SERVER_SERVICE_BOOKMARK_POST: return SERVICE_BOOST_BOOKMARK_POST;
        case SERVER_SERVICE_UPLOAD_CONTENT: return SERVICE_UPLOAD_CONTENT;
        case SERVER_SERVICE_REPORT: return SERVICE_REPORT;
        case SERVER_SERVICE_REPORT_SPAM_POST: return SERVICE_REPORT_SPAM_POST;
        case SERVER_SERVICE_OPTION_BOOST_COMMENT_FILE: return SERVICE_OPTION_BOOST_COMMENT_FILE;
        case SERVER_SERVICE_OPTION_BOOST_COMMENT_EMOJI: return SERVICE_OPTION_BOOST_COMMENT_EMOJI;
        case SERVER_SERVICE_OPTION_EMOJI_HAPPY_SET: return SERVICE_OPTION_EMOJI_HAPPY_SET;
        case SERVER_SERVICE_OPTION_EMOJI_IN_LOVE_SET: return SERVICE_OPTION_EMOJI_IN_LOVE_SET;
        case SERVER_SERVICE_OPTION_EMOJI_CONGRATULATION_SET: return SERVICE_OPTION_EMOJI_CONGRATULATION_SET;
        case SERVER_SERVICE_OPTION_EMOJI_ANGRY_SET: return SERVICE_OPTION_EMOJI_ANGRY_SET;
        case SERVER_TARGET_THAI_USER: return TARGET_THAI_USER;
        case SERVER_TARGET_FOREIGN_USER: return TARGET_FOREIGN_USER;
        default: return code;
    }
}

export const mapClientProductCodeToServerProductCode = (code: string): string => {
    switch (code) {
        case PRODUCT_CODE_FACEBOOK: return SERVER_PRODUCT_CODE_FACEBOOK;
        case PRODUCT_CODE_TIKTOK: return SERVER_PRODUCT_CODE_TIKTOK;
        case PRODUCT_CODE_INSTAGRAM: return SERVER_PRODUCT_CODE_INSTAGRAM;
        case PRODUCT_CODE_X: return SERVER_PRODUCT_CODE_X;
        case SERVICE_BOOST_FOLLOWER: return SERVER_SERVICE_BOOST_FOLLOWER;
        case SERVICE_BOOST_LIKE: return SERVER_SERVICE_BOOST_LIKE;
        case SERVICE_BOOST_FAVORITE: return SERVER_SERVICE_BOOST_FAVORITE;
        case SERVICE_BOOST_LIKE_POST: return SERVER_SERVICE_BOOST_LIKE_POST;
        case SERVICE_BOOST_VIEW: return SERVER_SERVICE_BOOST_VIEW;
        case SERVICE_BOOST_VIEW_VIDEO: return SERVER_SERVICE_BOOST_VIEW_VIDEO;
        case SERVICE_BOOST_LIVE_VIEW: return SERVER_SERVICE_BOOST_LIVE_VIEW;
        case SERVICE_BOOST_COMMENT: return SERVER_SERVICE_BOOST_COMMENT;
        case SERVICE_BOOST_COMMENT_POST: return SERVER_SERVICE_BOOST_COMMENT_POST;
        case SERVICE_BOOST_BOOKMARK_POST: return SERVER_SERVICE_BOOKMARK_POST;
        case SERVICE_BOOST_REPOST_POST: return SERVER_SERVICE_REPOST_POST;
        case SERVICE_BOOST_SHARE: return SERVER_SERVICE_BOOST_SHARE;
        case SERVICE_BOOST_SHARE_STORY: return SERVER_SERVICE_BOOST_SHARE_STORY;
        case SERVICE_BOOST_VIEW_STORY: return SERVER_SERVICE_BOOST_VIEW_STORY;
        case SERVICE_BOOST_QUOTE_POST: return SERVER_SERVICE_BOOST_QUOTE_POST;
        case SERVICE_BOOST_ADD_NOTE: return SERVER_SERVICE_BOOST_ADD_NOTE;
        case SERVICE_UPLOAD_CONTENT: return SERVER_SERVICE_UPLOAD_CONTENT;
        case SERVICE_REPORT: return SERVER_SERVICE_REPORT;
        case SERVICE_REPORT_SPAM_POST: return SERVER_SERVICE_REPORT_SPAM_POST;
        case SERVICE_OPTION_BOOST_COMMENT_FILE: return SERVER_SERVICE_OPTION_BOOST_COMMENT_FILE;
        case SERVICE_OPTION_BOOST_COMMENT_EMOJI: return SERVER_SERVICE_OPTION_BOOST_COMMENT_EMOJI;
        case SERVICE_OPTION_EMOJI_HAPPY_SET: return SERVER_SERVICE_OPTION_EMOJI_HAPPY_SET;
        case SERVICE_OPTION_EMOJI_IN_LOVE_SET: return SERVER_SERVICE_OPTION_EMOJI_IN_LOVE_SET;
        case SERVICE_OPTION_EMOJI_CONGRATULATION_SET: return SERVER_SERVICE_OPTION_EMOJI_CONGRATULATION_SET;
        case SERVICE_OPTION_EMOJI_ANGRY_SET: return SERVER_SERVICE_OPTION_EMOJI_ANGRY_SET;
        case TARGET_THAI_USER: return SERVER_TARGET_THAI_USER;
        case TARGET_FOREIGN_USER: return SERVER_TARGET_FOREIGN_USER;
        default: return '';
    }
}

export const getIconByCode = (code?: string) => {
    switch (code) {
        case PRODUCT_CODE_FACEBOOK: return FacebookIcon;
        case PRODUCT_CODE_TIKTOK: return TikTokIcon;
        case PRODUCT_CODE_INSTAGRAM: return InstagramIcon;
        case PRODUCT_CODE_X: return XIcon;
        case SERVICE_BOOST_FOLLOWER: return BoostFollowerIcon;
        case SERVICE_BOOST_LIKE:
        case SERVICE_BOOST_LIKE_POST:
        case SERVICE_BOOST_FAVORITE:
            return BoostLikeIcon;
        case SERVICE_BOOST_VIEW: return BoostViewIcon;
        case SERVICE_BOOST_VIEW_VIDEO: return BoostViewIcon;
        case SERVICE_BOOST_VIEW_STORY: return BoostViewStory;
        case SERVICE_BOOST_QUOTE_POST:
        case SERVICE_BOOST_SHARE:
        case SERVICE_BOOST_SHARE_STORY:
            return BoostShareIcon;
        case SERVICE_BOOST_LIVE_VIEW: return BoostLiveViewIcon;
        case SERVICE_BOOST_ADD_NOTE:
        case SERVICE_BOOST_COMMENT:
        case SERVICE_BOOST_COMMENT_POST:
            return BoostCommentIcon;
        case SERVICE_UPLOAD_CONTENT: return UploadContentIcon;
        case SERVICE_BOOST_REPOST_POST: return RepostPostIcon;
        case SERVICE_REPORT:
        case SERVICE_REPORT_SPAM_POST:
            return ReportIcon;
        case SERVICE_BOOST_BOOKMARK_POST: return BookmarkPostIcon;
        case SERVICE_OPTION_BOOST_COMMENT_FILE: return BoostCommentFileIcon;
        case SERVICE_OPTION_BOOST_COMMENT_EMOJI: return BoostCommentEmojiIcon;
        case SERVICE_OPTION_EMOJI_HAPPY_SET: return BoostEmojiHappySetIcon;
        case SERVICE_OPTION_EMOJI_IN_LOVE_SET: return BoostEmojiInLoveSetIcon;
        case SERVICE_OPTION_EMOJI_CONGRATULATION_SET: return BoostEmojiCongratulationSetIcon;
        case SERVICE_OPTION_EMOJI_ANGRY_SET: return BoostEmojiAngrySetIcon;
        case TARGET_THAI_USER: return ThaiUserIcon;
        case TARGET_FOREIGN_USER: return ForeignUserIcon;
        default: return FacebookIcon;
    }
}

export const getAmountUnitByServiceCode = (serviceCode: string) => {
    switch (serviceCode) {
        case SERVICE_BOOST_FOLLOWER: return `service.product.service.unit.${SERVICE_BOOST_FOLLOWER}`;
        case SERVICE_BOOST_LIKE: return `service.product.service.unit.${SERVICE_BOOST_LIKE}`;
        case SERVICE_BOOST_VIEW: return `service.product.service.unit.${SERVICE_BOOST_VIEW}`;
        case SERVICE_BOOST_SHARE: return `service.product.service.unit.${SERVICE_BOOST_SHARE}`;
        case SERVICE_BOOST_LIVE_VIEW: return `service.product.service.unit.${SERVICE_BOOST_LIVE_VIEW}`;
        case SERVICE_BOOST_COMMENT: return `service.product.service.unit.${SERVICE_BOOST_COMMENT}`;
        case SERVICE_REPORT: return `service.product.service.unit.${SERVICE_REPORT}`;
        default: return ''
    }
}

export const isPlatformTikTok = (platformCode: string) => platformCode === PRODUCT_CODE_TIKTOK
