import React, { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ServiceProductButton from './ProductButton';
import { Grid, Skeleton } from '@mui/material';
import { IServiceProductIcon } from '../../Component/Icon/ServiceIcon';
import isUndefined from 'lodash/isUndefined';
import FormLabelControl from '../../Component/Form/FormLabelControl';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';

export interface IServiceProductChoice {
    icon?: any;
    iconProps?: IServiceProductIcon;
    price?: number;
    amount?: number;
    code: string;
    label?: string | ReactElement;
    isInternal?: boolean | undefined;
    accountGroupType?: number;
    accountGroupTypeList?: number[];
    description?: string;
}

interface IProductGroup {
    labelGroup?: string;
    useLabelItem?: boolean;
    isRequired?: boolean;
    choices: Array<IServiceProductChoice> | undefined;
    choiceType: string;
    onChoose: (choice: IServiceProductChoice) => void;
    choiceSelected: IServiceProductChoice | undefined;
    dynamicIcon?: any;
    isIconVertical?: boolean;
    imgPreview?: any;
    isLoading?: boolean;
    errorMessage?: string;
    prefix?: string;
    platformCode?: string | undefined;
}

const ProductGroupStyled = styled(Box)(({ theme }) => ({
    '&.x-product-group': {
        margin: '2rem 0rem',
        '.-product-choice-wrapper': {
            marginTop: 0,
            display: 'flex',
            '.-loading-product-group-wrapper': {
                height: '62px',
                '.-loading-product-group': {
                    height: '100%',
                },
            },
            '.-error-message': {
                color: theme.baseVariables.colors.error,
                fontSize: theme.baseVariables.fonts.fontSizeSmall,
                margin: theme.spacing(0.5, 1.5, 0),
            },
        },
    },
}));

const ProductGroup: React.FC<IProductGroup> = ({
    labelGroup,
    choices,
    onChoose,
    choiceSelected,
    dynamicIcon,
    choiceType,
    errorMessage,
    imgPreview,
    platformCode,
    isLoading = false,
    isIconVertical = false,
    isRequired = true,
    useLabelItem = false,
    prefix = '฿',
}) => {
    const ImagePreviewIcon = imgPreview;

    return (
        <ProductGroupStyled className="x-product-group">
            {labelGroup && <FormLabelControl label={labelGroup} isRequired={isRequired} />}
            {imgPreview && choiceSelected?.code && <ImagePreviewIcon code={choiceSelected.code} platformCode={platformCode} />}
            {isLoading ? (
                <Grid container spacing={{ xxs: 1, sm: 1.25 }} columns={12} className={'-product-choice-wrapper'}>
                    {Array.from(Array(4)).map((i, index) => {
                        return (
                            <Grid
                                item
                                sm={3}
                                xxs={6}
                                className={'-loading-product-group-wrapper'}
                                key={`-loading-product-${choiceType}-${index}`}
                            >
                                <Skeleton className={'-loading-product-group'} variant="rounded" />
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Grid container spacing={{ xxs: 1, sm: 1.25 }} columns={12} className={'-product-choice-wrapper'}>
                    {choices &&
                        choices.map((choice, index) => {
                            const isSelected = choiceSelected?.code === choice.code;
                            // const isInternal = choice.isInternal;
                            const _icon = isUndefined(dynamicIcon) ? choice.icon : dynamicIcon;

                            return (
                                <Grid item sm={4} xxs={6} className={'-product-choice-item'} key={`${choice.code}-${index}`}>
                                    {useLabelItem && <FormLabelControl subLabel={choice.label as string} />}
                                    <ServiceProductButton
                                        label={useLabelItem ? '' : choice.label}
                                        icon={_icon}
                                        iconProps={choice.iconProps}
                                        extraClass={classNames(`choice-label-with-icon -${choiceType}`)}
                                        isActive={isSelected}
                                        onClick={() => onChoose(choice)}
                                        isIconVertical={isIconVertical}
                                        price={choice.price}
                                        prefix={prefix}
                                    />
                                    {errorMessage && (
                                        <Typography className={'-error-message'} variant={'body1'}>
                                            {errorMessage}
                                        </Typography>
                                    )}
                                </Grid>
                            );
                        })}
                </Grid>
            )}
        </ProductGroupStyled>
    );
};

export default ProductGroup;
