import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTrans } from '@core/Hook/trans';
import Title from '../../Component/Title';
import { getAmountUnitByServiceCode, getIconByCode, isPlatformTikTok } from '../../Constants/product';
import { useManageProductInfo } from '../../Hook/product';
import ProductForm from './ProductForm';
import { useRecoilValue } from 'recoil';
import { IPackageData, IPlatformData, IServiceData, IServiceOptionData, ITargetData, ProductsAtom } from '../../Recoil/Product/atom';
import isEmpty from 'lodash/isEmpty';
import { NumericFormat } from 'react-number-format';
import isUndefined from 'lodash/isUndefined';
import { flatten } from 'lodash';
import { useLocation } from 'react-router-dom';
import { OrderSingleAtom } from '../../Recoil/Order/atom';
import { InitialProductInfoByPreviousOrderSelector, useFetchProducts } from '../../Recoil/Product/selector';
import ServiceImagePreview from './ServiceImagePreview';
import { handleResponseFormError, resolveClientApiState, useClientApiState } from '@core/Hook/api';
import { getLastestSelectPurchaseOrder } from '../../Api/product';
import ProductGroup, { IServiceProductChoice } from '../../Page/Product/ProductGroup';
import { ACCOUNT_GROUP_INTERNAL_LIST } from "../../Constants/common";
import { CustomerInfoAtom } from "../../Recoil/Common/atom";

const ProductStyled = styled(Container)(({ theme }) => ({
    '&.x-product-container': {
        padding: theme.spacing(0, 2, 0),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 3, 5),
        },
        '.-product-container': {
            background: theme.baseVariables.serviceProduct.bg,
            border: theme.baseVariables.serviceProduct.border,
            borderRadius: theme.spacing(1),
            padding: theme.spacing(4, 2),
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(4, 4),
            },
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(4, 6),
            },
            '.-product-group-wrapper': {
                marginTop: theme.spacing(2),
                [theme.breakpoints.between('xxs', 380)]: {
                    marginTop: theme.spacing(1.5),
                },
                [theme.breakpoints.up('sm')]: {
                    marginTop: theme.spacing(3),
                },
            },
        },
        '.-ps-wrapper': {
            background: 'rgb(238 244 252 / 20%)',
            '.-ps-title': {
                fontWeight: theme.baseVariables.fonts.fontWeightSemiBold,
                color: '#34406b',
            },
            '.-dot': {
                color: '#eb5757',
            },
        },
    },
}));

const Product: React.FC = () => {
    const { trans } = useTrans();
    const {
        productChooseInfo,
        saveProductPlatform,
        saveProductService,
        saveProductTarget,
        saveProductPackage,
        saveProductServiceOptionType,
        saveProductServiceOptionTypeValue,
        clearProductInfo,
        clearProductInfoByKey,
        initialProductInfo,
    } = useManageProductInfo();
    const products = useRecoilValue(ProductsAtom);
    const location = useLocation();
    const orderSingle = useRecoilValue(OrderSingleAtom);
    const isReorder = !isUndefined(location.state?.orderId) && !isUndefined(orderSingle);
    const initialProductByPreviousOrder = useRecoilValue(
        InitialProductInfoByPreviousOrderSelector(orderSingle?.purchaseOrderProduct.productItemFullCode),
    );
    const [previousSelectOrder, setPreviousSelectOrder] = useState(undefined);
    const [apiState, setApiState] = useClientApiState({ isLoading: false });
    const [apiLastPurchaseState, setApiLastPurchaseState] = useClientApiState({ isLoading: false });
    const [errorMessages, setErrorMessages] = useState<Array<{ [key: string]: string }> | undefined>();
    const fetchProducts = useFetchProducts();
    const customerInfo = useRecoilValue(CustomerInfoAtom)
    const platforms: IServiceProductChoice[] = isEmpty(products.platforms)
        ? []
        : products.platforms.map((platform: IPlatformData) => {
              return {
                  code: platform.platformCode,
                  label: trans(`service.product.platform.${platform.platformCode}`),
                  icon: getIconByCode(platform.platformCode),
                  iconProps: {
                      isRoundedBg: true,
                  },
              };
          });

    const serviceTypes: IServiceProductChoice[] = isEmpty(products.services)
        ? []
        : products.services
              .filter((service: IServiceData) => service.platformCode === productChooseInfo.platform?.code)
              .map((service: IServiceData) => {
                  return {
                      code: service.platformServiceCode,
                      label: trans(`service.product.service.${service.platformServiceCode}`),
                      icon: getIconByCode(service.platformServiceCode),
                      description: service.platformServiceDescription,
                  };
              });

    const targetUsers: IServiceProductChoice[] = isEmpty(products.targets)
        ? []
        : products.targets
              .filter(
                  (target: ITargetData) =>
                      target.platformCode === productChooseInfo.platform?.code &&
                      target.platformServiceCode === productChooseInfo.service?.code,
              )
              .map((target: ITargetData) => {
                  return {
                      code: target.targetAccountCode,
                      label: trans(`service.product.target.${target.targetAccountCode}`),
                      icon: getIconByCode(target.targetAccountCode),
                  };
              });

    const packages: IServiceProductChoice[] = isEmpty(products.packagesInfo)
        ? []
        : products.packagesInfo
              .filter(
                  (packageInfo: IPackageData) =>
                      packageInfo.platformCode === productChooseInfo.platform?.code &&
                      packageInfo.platformServiceCode === productChooseInfo.service?.code &&
                      packageInfo.targetAccountCode === productChooseInfo.target?.code,
              )
              .map((packageInfo: IPackageData) => {
                  const numberAmount = (
                      <NumericFormat
                          suffix={` ${trans(getAmountUnitByServiceCode(packageInfo.platformServiceCode))}`}
                          thousandSeparator
                          displayType={'text'}
                          className={'-number-text'}
                          value={packageInfo.amount}
                      />
                  );

                  return {
                      code: packageInfo.productItemCode,
                      label: numberAmount,
                      icon: getIconByCode(packageInfo.productItemCode),
                      price: packageInfo.price,
                      amount: packageInfo.amount,
                      accountGroupType: customerInfo?.accountGroupType,
                      isInternal: ACCOUNT_GROUP_INTERNAL_LIST.some(value => {
                          return packageInfo.accountGroupTypeList.includes(value)
                      }),
                  };
              });

    const serviceOptions: IServiceProductChoice[] = isUndefined(products.serviceOptions)
        ? []
        : products.serviceOptions
              .filter(
                  (serviceOption: IServiceOptionData) =>
                      serviceOption.platformCode === productChooseInfo.platform?.code &&
                      serviceOption.platformServiceCode === productChooseInfo.service?.code,
              )
              .map((serviceOption: IServiceOptionData) => {
                  return {
                      code: serviceOption.typeCode,
                      label: trans(`service.product.service_option_type.${serviceOption.typeCode}`),
                      icon: getIconByCode(serviceOption.typeCode),
                      optionValues: serviceOption.dataSet,
                  };
              });

    const serviceOptionTypeValues = isEmpty(serviceOptions)
        ? []
        : products.serviceOptions &&
          flatten(
              products.serviceOptions
                  .filter(
                      (serviceOption: IServiceOptionData) =>
                          serviceOption.platformCode === productChooseInfo.platform?.code &&
                          serviceOption.platformServiceCode === productChooseInfo.service?.code &&
                          serviceOption.typeCode === productChooseInfo.serviceOptionType?.code &&
                          !isEmpty(serviceOption.dataSet),
                  )
                  .map((serviceOption: IServiceOptionData) => {
                      return serviceOption.dataSet.map((data) => {
                          return {
                              code: data.setCode,
                              label: trans(`service.product.service_option_type_value.${data.setCode}`),
                              icon: getIconByCode(data.setCode),
                              iconProps: {
                                  extraClass: '-emoji-icon-set',
                              },
                          };
                      });
                  }),
          );

    const isPlatformType = (type: string) => 'platform' === type;
    const isServiceType = (type: string) => 'service' === type;
    const isServiceOptionType = (type: string) => 'serviceOptionType' === type;
    const isServiceOptionTypeValue = (type: string) => 'serviceOptionTypeValue' === type;
    const isTargetUserType = (type: string) => 'target' === type;
    const isPackageType = (type: string) => 'package' === type;
    const handleProductChoose = (choice: IServiceProductChoice, type: string) => {
        if (isPlatformType(type)) {
            clearProductInfo();
            saveProductPlatform(choice);
            return;
        }

        if (isServiceType(type)) {
            clearProductInfoByKey('serviceOptionType');
            saveProductService(choice);
            return;
        }

        if (isTargetUserType(type)) return saveProductTarget(choice);
        if (isPackageType(type)) return saveProductPackage(choice);
        if (isServiceOptionTypeValue(type)) return saveProductServiceOptionTypeValue(choice);
        if (isServiceOptionType(type)) {
            clearProductInfoByKey('serviceOptionTypeValue');
            saveProductServiceOptionType(choice);
        }
    };

    const handleErrorMessages = (errorMessages: any) => setErrorMessages(errorMessages);
    const getErrorMessage = (key: string) => {
        if (isUndefined(errorMessages)) return undefined;

        return errorMessages?.map((errorMessage: { [key: string]: string }) => {
            if (errorMessage[key]) return errorMessage[key];
        });
    };

    const initialProductByPreviousSelectOrder = useRecoilValue(InitialProductInfoByPreviousOrderSelector(previousSelectOrder));
    const getLastestSelectPurchaseOrderData = async () => {
        setApiLastPurchaseState({ ...apiLastPurchaseState, isLoading: true });
        const response = resolveClientApiState(await getLastestSelectPurchaseOrder(), true);
        if (response.isOk) {
            setApiLastPurchaseState({ ...apiLastPurchaseState, isLoading: false });
            setPreviousSelectOrder(response.data.productItemFullCode);

            return;
        }
        handleResponseFormError(response);
        setApiLastPurchaseState({ ...apiLastPurchaseState, isLoading: false });
    };

    useEffect(() => {
        if (isReorder) return;
        getLastestSelectPurchaseOrderData();
    }, []);

    useEffect(() => {
        if (isReorder || isUndefined(initialProductByPreviousSelectOrder)) return;
        initialProductInfo(initialProductByPreviousSelectOrder);
    }, [initialProductByPreviousSelectOrder]);

    useEffect(() => {
        if (isEmpty(products.platforms)) fetchProducts(apiState, setApiState);

        if (isUndefined(products.platforms)) return;
        if (isReorder && !isUndefined(initialProductByPreviousOrder)) {
            initialProductInfo(initialProductByPreviousOrder);

            return;
        }
        initialProductInfo();
    }, [products, isReorder]);

    const renderServiceDescription = (service: IServiceProductChoice | undefined) => {
        return (
            service &&
            service.description && (
                <Box className={'-ps-wrapper'} marginY={4} paddingY={{ xs: 2 }} paddingX={{ xs: 2, md: 4 }} borderRadius={0.75}>
                    {trans('service.product.service.description')}
                    <div
                        dangerouslySetInnerHTML={{
                            __html: service.description as string,
                        }}
                    />
                </Box>
            )
        );
    };

    return (
        <ProductStyled className="x-product-container">
            <Box className={'-product-container'}>
                <Title title={trans('service.product.title')} subTitle={trans('service.product.sub_title')} />
                <Box className={'-product-group-wrapper'}>
                    <ProductGroup
                        labelGroup={trans('service.product.platform.title')}
                        choices={platforms}
                        onChoose={(choice) => handleProductChoose(choice, 'platform')}
                        choiceSelected={productChooseInfo.platform}
                        choiceType={'platform'}
                        isLoading={apiState.isLoading}
                        errorMessage={getErrorMessage('platform')?.join('')}
                    />
                </Box>
                <Box className={'-product-group-wrapper'}>
                    <ProductGroup
                        platformCode={productChooseInfo.platform?.code}
                        labelGroup={trans('service.product.service.title')}
                        choices={serviceTypes}
                        onChoose={(choice) => handleProductChoose(choice, 'service')}
                        choiceSelected={productChooseInfo.service}
                        choiceType={'service'}
                        imgPreview={ServiceImagePreview}
                        isLoading={apiState.isLoading}
                        errorMessage={getErrorMessage('service')?.join('')}
                    />
                </Box>
                {renderServiceDescription(productChooseInfo.service)}
                <Box display={targetUsers.length == 1 ? 'none' : 'block'} className={'-product-group-wrapper'}>
                    <ProductGroup
                        labelGroup={trans('service.product.target.title')}
                        choices={targetUsers}
                        onChoose={(choice) => handleProductChoose(choice, 'target')}
                        choiceSelected={productChooseInfo.target}
                        choiceType={'target'}
                        isLoading={apiState.isLoading}
                        errorMessage={getErrorMessage('target')?.join('')}
                    />
                </Box>
                <Box className={'-product-group-wrapper'}>
                    <ProductGroup
                        labelGroup={trans('service.product.package.title')}
                        choices={packages}
                        onChoose={(choice) => handleProductChoose(choice, 'package')}
                        choiceSelected={productChooseInfo.packageInfo}
                        dynamicIcon={productChooseInfo.service?.icon}
                        isIconVertical
                        choiceType={'package'}
                        isLoading={apiState.isLoading}
                        errorMessage={getErrorMessage('package')?.join('')}
                    />
                </Box>
                {!isEmpty(serviceOptions) && (
                    <Box className={'-product-group-wrapper'}>
                        <ProductGroup
                            labelGroup={trans(`service.product.service_option.${productChooseInfo.service?.code}`)}
                            choices={serviceOptions}
                            onChoose={(choice) => handleProductChoose(choice, 'serviceOptionType')}
                            choiceSelected={productChooseInfo.serviceOptionType}
                            choiceType={'serviceOptionType'}
                            isLoading={apiState.isLoading}
                            errorMessage={getErrorMessage('serviceOptionType')?.join('')}
                        />
                    </Box>
                )}
                {productChooseInfo.serviceOptionType && serviceOptionTypeValues && (
                    <ProductGroup
                        useLabelItem
                        choices={serviceOptionTypeValues}
                        onChoose={(choice) => handleProductChoose(choice, 'serviceOptionTypeValue')}
                        choiceSelected={productChooseInfo.serviceOptionTypeValue}
                        choiceType={'serviceOptionTypeValue'}
                        isLoading={apiState.isLoading}
                        errorMessage={getErrorMessage('serviceOptionTypeValue')?.join('')}
                    />
                )}

                {/*<Box*/}
                {/*    className={'-ps-wrapper'}*/}
                {/*    marginY={4}*/}
                {/*    paddingY={{xs: 2}}*/}
                {/*    paddingX={{xs: 2, md: 4}}*/}
                {/*    borderRadius={.75}*/}
                {/*>*/}
                {/*    <Typography className={'-ps-title'} variant={'body2'}>*/}
                {/*        <span className={'-dot'}>{'**'}</span> {trans('service.ps.title')}*/}
                {/*    </Typography>*/}
                {/*    {Array.from(Array(4), (_i, index) => {*/}
                {/*        return (*/}
                {/*            <Typography*/}
                {/*                key={`item-text-${index}`}*/}
                {/*                variant={'body2'}*/}
                {/*            >*/}
                {/*                {`${index + 1}. ${trans(`service.ps.no_${index + 1}`)}`}*/}
                {/*            </Typography>*/}
                {/*        )*/}
                {/*    })}*/}
                {/*</Box>*/}

                <ProductForm
                    productInfo={productChooseInfo}
                    accountName={isReorder ? orderSingle.purchaseOrderProgress.socialAccountName : ''}
                    accountLink={isReorder ? orderSingle.purchaseOrderProgress.link : ''}
                    handleErrorMessage={(errorMessages: any) => handleErrorMessages(errorMessages)}
                />
            </Box>
        </ProductStyled>
    );
};

export default Product;
