import React from "react";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {Image} from "@core/Utils/image";
import {
    PRODUCT_CODE_FACEBOOK,
    PRODUCT_CODE_INSTAGRAM,
    PRODUCT_CODE_TIKTOK,
    PRODUCT_CODE_X,
    SERVICE_BOOST_ADD_NOTE, SERVICE_BOOST_FAVORITE,
    SERVICE_BOOST_LIKE,
    SERVICE_BOOST_LIKE_POST,
    SERVICE_BOOST_SHARE_STORY,
    SERVICE_BOOST_VIEW,
    SERVICE_BOOST_VIEW_STORY
} from "../../Constants/product";


const ServiceImagePreviewStyled = styled(Box)(({ theme }) => ({
    '&.x-service-preview-image': {
        width: '250px',
        margin: theme.spacing(2, 'auto'),
        [theme.breakpoints.between('xxs', 380)]: {
            width: '220px',
        },
    }
}))

export interface IServiceImagePreview {
    code: string;
    platformCode: string;
}

const ServiceImagePreview: React.FC<IServiceImagePreview> = ({code, platformCode}) => {
    let serviceImagePreview = `wow-boost-service-${code}-preview.gif`

    if (platformCode !== PRODUCT_CODE_TIKTOK) {
        serviceImagePreview = `wow-boost-service-${platformCode}-${code}-preview.gif`
    }

    if (platformCode === PRODUCT_CODE_FACEBOOK) {
        if ([SERVICE_BOOST_VIEW, SERVICE_BOOST_LIKE_POST, SERVICE_BOOST_FAVORITE].includes(code)) return <></>;
    }

    if (platformCode === PRODUCT_CODE_INSTAGRAM) {
        if ([SERVICE_BOOST_LIKE, SERVICE_BOOST_ADD_NOTE, SERVICE_BOOST_SHARE_STORY, SERVICE_BOOST_VIEW_STORY, SERVICE_BOOST_VIEW].includes(code)) return <></>;
    }

    if (platformCode === PRODUCT_CODE_TIKTOK) {
        if ([SERVICE_BOOST_LIKE, SERVICE_BOOST_LIKE_POST, SERVICE_BOOST_VIEW, SERVICE_BOOST_FAVORITE].includes(code)) return <></>;
    }

    if (platformCode === PRODUCT_CODE_X) {
        if ([SERVICE_BOOST_LIKE, SERVICE_BOOST_LIKE_POST, SERVICE_BOOST_VIEW].includes(code)) return <></>;
    }

    return (
        <ServiceImagePreviewStyled className="x-service-preview-image">
            <Image
                extraClass={'-service-preview-image'}
                src={serviceImagePreview}
                width={450}
                height={600}
                webp={false}
            />
        </ServiceImagePreviewStyled>
    )
}

export default ServiceImagePreview
