import React from "react";
import ServiceProductIcon, {getSvgServiceIconColor, IServiceProductIcon} from "./ServiceIcon";
import isUndefined from "lodash/isUndefined";

const BoostViewStory: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const color = getSvgServiceIconColor(!isUndefined(isActive) ? isActive : false);

    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 512 512" fill="none">
                <path
                    d="M272.32 18.1601C264.576 17.3921 257.52 23.6161 256.992 31.5521C256.448 39.4881 262.448 46.3521 270.384 46.8801C379.84 54.3041 465.6 146.16 465.6 256C465.6 371.584 371.584 465.6 256 465.6C195.344 465.6 137.696 439.344 97.8397 393.568C92.6077 387.584 83.5037 386.96 77.5197 392.16C71.5197 397.376 70.8957 406.48 76.1117 412.48C121.456 464.528 187.008 494.4 256 494.4C387.456 494.4 494.4 387.44 494.4 256C494.4 131.056 396.848 26.5761 272.32 18.1601Z"
                    fill={color} />
                <path
                    d="M134.879 81.92C137.599 81.92 140.319 81.104 142.719 79.664C142.719 79.504 142.879 79.504 142.879 79.504C159.679 68.624 177.919 60.304 196.959 54.864C204.495 52.624 208.959 44.48 206.719 36.944C204.479 29.6 196.319 24.96 188.799 27.2C167.039 33.584 146.239 43.024 127.039 55.36C123.023 58.08 120.479 62.56 120.479 67.52C120.479 70.4 121.279 72.96 122.719 75.36C125.439 79.52 129.919 81.92 134.879 81.92Z"
                    fill={color} />
                <path
                    d="M46.3836 256C46.3836 248 39.9996 241.6 31.9996 241.6C23.9996 241.6 17.5996 248 17.5996 256C17.5996 278.56 20.7996 300.8 26.8796 322.08C27.0396 322.4 27.0396 322.88 27.2156 323.2V323.36C29.1196 329.28 34.7356 333.44 40.9596 333.44C42.3996 333.44 43.6796 333.28 44.9596 332.96C51.2156 331.04 55.5196 325.44 55.3596 319.04C55.3596 317.6 55.1996 316.32 54.8796 315.04C49.2796 295.84 46.3836 276 46.3836 256Z"
                    fill={color} />
                <path
                    d="M85.2801 110.24C79.0241 105.6 69.7441 106.88 65.1041 113.104C51.5201 131.36 40.4801 151.36 32.6401 172.64C32.0001 174.24 31.6641 175.84 31.6641 177.6C31.6641 183.52 35.3441 189.12 40.9441 191.216C42.5601 191.664 44.3201 192 46.0801 192C52.0001 192 57.4401 188.32 59.5201 182.72C66.5601 164 76.1601 146.384 88.1601 130.384C92.9601 124 91.6641 115.024 85.2801 110.24Z"
                    fill={color} />
                <path
                    d="M266.413 235.54C266.413 224.97 271.664 215.654 279.585 210.011C272.465 206.339 264.544 204.099 256 204.099C227.253 204.099 203.846 227.657 203.846 256.59C203.846 285.522 227.253 309.08 256 309.08C281.721 309.08 303.081 290.18 307.264 265.457C286.26 272.265 266.413 256.321 266.413 235.629V235.54ZM431.33 249.513C428.126 245.93 351.675 162 256 162C160.325 162 83.874 245.93 80.67 249.513C77.11 253.544 77.11 259.545 80.67 263.487C83.874 267.07 160.325 351 256 351C351.675 351 428.126 267.07 431.33 263.487C434.89 259.456 434.89 253.455 431.33 249.513ZM256 329.95C215.772 329.95 183.02 296.987 183.02 256.5C183.02 216.013 215.772 183.05 256 183.05C296.228 183.05 328.98 216.013 328.98 256.5C328.98 296.987 296.228 329.95 256 329.95Z"
                    fill={color} />
            </svg>
        </ServiceProductIcon>
    )
}

export default BoostViewStory
