import React from "react";
import ServiceProductIcon, {getSvgServiceIconColor, IServiceProductIcon} from "./ServiceIcon";
import isUndefined from "lodash/isUndefined";

const UploadContentIcon: React.FC<IServiceProductIcon> = (props) => {
    const {isActive} = props;
    const color = getSvgServiceIconColor(!isUndefined(isActive) ? isActive : false);

    return (
        <ServiceProductIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 512 512" fill="none">
                <path
                    d="M422.948 98.1584L358.126 38.2334C346.376 27.0708 330.906 21 314.651 21H132.722C97.4715 21 68.6846 49.7869 68.6846 85.0379V426.962C68.6846 462.212 97.4715 491 132.722 491H379.276C414.526 491 443.314 462.212 443.314 426.962V145.158C443.314 127.337 435.872 110.3 422.948 98.1584ZM192.059 181.974C245.002 129.031 243.165 130.607 245.131 129.295C248.263 127.141 252.18 125.966 255.901 125.966H256.097C261.189 125.966 266.085 128.12 269.806 131.646C290.215 152.135 321.991 184.035 319.939 181.974C327.576 189.612 327.576 201.95 319.939 209.588C312.301 217.225 299.964 217.225 292.326 209.588L275.681 192.941V262.071C275.681 273.037 266.868 281.654 256.097 281.654C245.131 281.654 236.514 273.037 236.514 262.071V192.941L219.672 209.784C212.344 217.112 199.849 217.573 192.059 209.784C184.422 202.146 184.422 189.612 192.059 181.974ZM331.885 386.034H180.114C169.343 386.034 160.53 377.221 160.53 366.45C160.53 355.678 169.343 346.867 180.114 346.867H331.885C342.655 346.867 351.468 355.678 351.468 366.45C351.468 377.221 342.655 386.034 331.885 386.034Z"
                    fill={color} />
            </svg>
        </ServiceProductIcon>
    )
}

export default UploadContentIcon
